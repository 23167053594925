import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ThemeKey } from '../assets/css/variables';

import { DeliveryMethod, GetRedactedPartner200Response, RedactedLocation } from 'sparrowhub-client-axios';

// ----------------------------------------------------------- TYPES

export enum ProgressStepKeys {
  Index = 'index',
  Patient = 'patient',
  PrescriptionList = 'prescription_list',
  PrescriptionAddAnother = 'prescription_add_another',
  PrescriptionConfirm = 'prescription_confirm',
  PrescriptionFailover = 'prescription_failover',
  Delivery = 'delivery',
  Checkout = 'checkout'
}

export interface Config {
  locations: Array<RedactedLocation>,
  partner_id: number | null
  payment_integration_type: 'tyro' | 'stripe' | null,
  payment_integration_key: string | null,
  cart_id: number | null
  has_landed: boolean
  theme_key: ThemeKey | null
  location_code: string | null
  location_name: string | null
  location_address: string | null
  location_phone: string | null
  location_email: string | null
  error: any
  delivery_methods: Array<DeliveryMethod>
  recaptcha_key: string | null
  auto_select_products: boolean
  mode: 'embed' | null
  progress_step_key: ProgressStepKeys
}

export interface OrderDetails {
  order_number: string
  delivery_method: string
  delivery_method_name: string
}

export interface Prescription {
  name: string
  token: string
  script_firstname: string
  script_lastname: string
  customer_firstname: string
  customer_lastname: string
  customer_email: string
  delivery_phone: string
  entitlement: string
  entitlement_number: string
  dosage: Array<any>
  products: Array<any>
  selected_product_index: number
  confirmed: boolean
}

export interface PrescriptionPayload extends Partial<Prescription> {
  index?: number
}

export interface DeliveryDetails {
  delivery_firstname: string
  delivery_lastname: string
  delivery_email: string
  delivery_phone: string
  delivery_street: string
  delivery_city: string
  delivery_state_code: string
  delivery_postcode: string
  delivery_method: string
}

export interface BillingDetails {
  selected_payment: string
  card_number: string
  expiry_month: string
  expiry_year: string
  ccv: string
  card_name: string
  billing_firstname: string
  billing_lastname: string
  billing_email: string
  billing_phone: string
  billing_street: string
  billing_city: string
  billing_state_code: string
  billing_postcode: string
}

export interface IIndexable<T = any> {
  [key: string]: T
}

interface ScriptState {
  config: Config
  order: OrderDetails
  scripts: Array<Prescription>
  delivery: DeliveryDetails
  billing: BillingDetails
}

// ----------------------------------------------------------- INITIAL STATE

const initialState: ScriptState = {
  config: {
    locations: [],
    partner_id: null,
    payment_integration_type: null,
    payment_integration_key: null,
    cart_id: null,
    has_landed: false,
    theme_key: null,
    location_code: null,
    location_name: null,
    location_address: null,
    location_phone: null,
    location_email: null,
    error: null,
    delivery_methods: [],
    recaptcha_key: null,
    auto_select_products: true,
    mode: null,
    progress_step_key: ProgressStepKeys.Index,
  },
  order: {
    order_number: '',
    delivery_method: '',
    delivery_method_name: ''
  },
  scripts: [
    {
      name: '',
      token: '',
      script_firstname: '',
      script_lastname: '',
      customer_firstname: '',
      customer_lastname: '',
      customer_email: '',
      delivery_phone: '',
      entitlement: 'private',
      entitlement_number: '',
      dosage: [],
      products: [],
      selected_product_index: 0,
      confirmed: false,
    }
  ],
  delivery: {
    delivery_firstname: '',
    delivery_lastname: '',
    delivery_email: '',
    delivery_phone: '',
    delivery_street: '',
    delivery_city: '',
    delivery_state_code: '',
    delivery_postcode: '',
    delivery_method: ''
  },
  billing: {
    selected_payment: '',
    card_number: '',
    expiry_month: '',
    expiry_year: '',
    ccv: '',
    card_name: '',
    billing_firstname: '',
    billing_lastname: '',
    billing_email: '',
    billing_phone: '',
    billing_street: '',
    billing_city: '',
    billing_state_code: '',
    billing_postcode: '',
  }
}

// ----------------------------------------------------------- UTIL METHODS

export const getEntitlementPrice = (script: Prescription): number | null => {
  const entitlementPrice = getProductEntitlementPrice(script.products[script.selected_product_index], script.entitlement);
  if (entitlementPrice !== null) {
    return entitlementPrice;
  } else {
    console.warn('No price for selected entitlement, returning private price.');
    return getProductEntitlementPrice(script.products[script.selected_product_index], 'private');
  }
}

export const getProductEntitlementPrice = (product: any, entitlement: string): number | null => {
  switch (entitlement) {
    case 'private':
      return product.price_private_in_cents === null ? null : formatPriceFromInt(product.price_private_in_cents);
    case 'pbs':
      return product.price_general_in_cents === null ? null : formatPriceFromInt(product.price_general_in_cents);
    case 'concession':
      return product.price_concessional_in_cents === null ? null : formatPriceFromInt(product.price_concessional_in_cents);
    case 'safety_net':
      // safety net price is always 0
      return formatPriceFromInt(0);
    default:
      console.warn(`Cannot get price for entitlement ${entitlement}, returning null.`)
      return null;
  }
}

export const getBasketSubtotal = (scripts: Array<Prescription>): number => {
  let subtotal = 0;
  scripts.forEach((script: Prescription) => {
    if (script.confirmed) {
      const price = getEntitlementPrice(script);
      if (price !== null) {
        subtotal += price;
      }
    }
  });
  return subtotal;
}

export const formatItemObject = (script: Prescription): any => {
  const product = script.products[0];
  return {
    'item_id': product.barcode,
    'item_name': product.name,
    'item_brand': product.brand_name,
    'price': getEntitlementPrice(script),
    'quantity': 1,
    'gtin': product.barcode
  }
}

export const formatItemsArray = (scripts: Array<Prescription>): any => {
  return scripts.map(script => formatItemObject(script));
}

export const formatPhoneString = (phone: String): String => {
  if (phone.startsWith('+614')) {
    // mobile
    return `0${phone.substring(3, 6)} ${phone.substring(6, 9)} ${phone.substring(9, 12)}`
  } else if (phone.startsWith('+612')) {
    // landline
    return `(02) ${phone.substring(4, 8)} ${phone.substring(8, 12)}`
  } else {
    // fallback
    return phone;
  }
}

export const formatPriceFromInt = (price: number): number => {
  return price / 100;
}

export const formatPriceFromFloat = (price: number): number => {
  return Math.round(price * 10000) / 10000;
}

export const formatPriceToInt = (price: number): number => {
  const str = price.toString()
  const int = str.split('.')
  return Number(price.toFixed(2).replace('.', '').padEnd(int.length === 1 ? 3 : 4, '0'))
}

// Helper function to get a patient's name from the medication knowledge data
export const getPatientName = (medication_knowledge: any): { script_firstname: string | undefined; script_lastname: string | undefined } => {
  // setup attributes
  let idx = 0;
  
  // get the patient resource
  let medication_knowledge_patient: any | undefined;
  if (
    medication_knowledge &&
    medication_knowledge.entry &&
    medication_knowledge.entry.indexOf(idx) > -1 &&
    medication_knowledge.entry[idx].resource &&
    medication_knowledge.entry[idx].resource.contained &&
    medication_knowledge.entry[idx].resource.contained.find((resource: any) => resource.resourceType === 'Patient')
  ) {
    medication_knowledge_patient = medication_knowledge.entry[idx].resource.contained.find((resource: any) => resource.resourceType === 'Patient');
  } else {
    return {
      script_firstname: undefined,
      script_lastname: undefined
    }
  }

  // get patient name
  if (medication_knowledge_patient && 
    medication_knowledge_patient.name && 
    medication_knowledge_patient.name.indexOf(idx) > -1 &&
    medication_knowledge_patient.name[idx].given &&
    medication_knowledge_patient.name[idx].family
  ) {
    return {
      script_firstname: medication_knowledge_patient.name[idx].given.join(' '),
      script_lastname: medication_knowledge_patient.name[idx].family
    }
  } else {
    return {
      script_firstname: undefined,
      script_lastname: undefined
    }
  }
}

// ----------------------------------------------------------- SLICE

export const scriptSlice = createSlice({
  name: 'script',
  initialState: initialState,
  reducers: {
    // reset state
    resetState: (state) => {
      const initial = initialState;

      // reset all state except config and order
      state.scripts = initial.scripts;
      state.delivery = initial.delivery;
      state.billing = initial.billing;

      // reset config.delivery_methods and config.progress_step_key
      state.config.delivery_methods = [];
      state.config.progress_step_key = ProgressStepKeys.Index;
      state.config.cart_id = null;
    },
    // config
    setConfig: (state, action: PayloadAction<Partial<Config>>) => {
      for (const [key, value] of Object.entries(action.payload)) {
        (state.config as IIndexable)[key] = value;
      }
    },
    setHasLanded: (state) => {
      state.config.has_landed = true;
    },
    // scripts
    addScript: (state, action: PayloadAction<PrescriptionPayload>) => {
      state.scripts = [...state.scripts, action.payload] as Array<Prescription>;
    },
    removeScript: (state, action: PayloadAction<PrescriptionPayload>) => {
      if (action.payload.index === undefined || typeof action.payload.index !== 'number' || action.payload.index < 0) {
        console.warn('Cannot remove script without index');
      } else {
        state.scripts = [
          ...state.scripts.slice(0, action.payload.index),
          ...state.scripts.slice(action.payload.index + 1)
        ] as Array<Prescription>;
      }
    },
    setScript: (state, action: PayloadAction<PrescriptionPayload>) => {
      if (action.payload.index === undefined || typeof action.payload.index !== 'number' || action.payload.index < 0) {
        console.warn('Cannot set script without index');
      } else {
        for (const [key, value] of Object.entries(action.payload)) {
          if (key !== 'index') {
            (state.scripts as IIndexable)[action.payload.index][key] = value;
          }
        }
      }
    },
    // order
    setOrder: (state, action: PayloadAction<Partial<OrderDetails>>) => {
      for (const [key, value] of Object.entries(action.payload)) {
        (state.order as IIndexable)[key] = value;
      }
    },
    // delivery
    setDelivery: (state, action: PayloadAction<Partial<DeliveryDetails>>) => {
      for (const [key, value] of Object.entries(action.payload)) {
        (state.delivery as IIndexable)[key] = value;
      }
    },
    // billing
    setBilling: (state, action: PayloadAction<Partial<BillingDetails>>) => {
      for (const [key, value] of Object.entries(action.payload)) {
        (state.billing as IIndexable)[key] = value;
      }
    },
  },
})

export const {
  resetState,
  setConfig,
  setHasLanded,
  addScript,
  removeScript,
  setScript,
  setOrder,
  setDelivery,
  setBilling
} = scriptSlice.actions

export default scriptSlice.reducer
