// ------------------------------------------------------------ ASSET IMPORTS

// Default
const defaultFavicon = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-sparrowscript-sparrow-circle.png`;

// Priceline
const footerBackground = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/footer-bg.svg`;
const pricelineFavicon = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/favicon-priceline.png`;
const pricelineLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-priceline-rounded.png`;
const pricelineLogoHeader = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-priceline-rounded.png`;

// Smith's Pharamcy
const smithsLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-smiths.svg`;

// Chemistworks
const chemistworksLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-chemistworks.png`;

// Cat & Claudias
const catAndClaudiasLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-catandclaudias.png`;
const catAndClaudiasLogoHeader = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/catandclaudias_white.png`;
const catAndClaudiasIcon = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/catandclaudias_white_plus.png`;

// Folium Dispensary
const foliumLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-folium-space.svg`;

// ------------------------------------------------------------ TYPE DEFS

export enum ThemeKey {
  Default = 'default',
  Priceline = 'priceline',
  SmithsPharmacy = 'smiths',
  Chemistworks = 'chemistworks',
  CatAndClaudias = 'cat-claudias',
  FoliumDispensary = 'folium',
}

export interface ITheme {
  id: ThemeKey
  // theme
  data: {
    name: string
    dummyLocation: string
    phoneDisplay?: string
    phone: string
  }
  colours: {
    primary: string
    primaryDark: string
    primaryHighlight: string
    secondary: string
    black: string
    white: string
    grey: string
    background: string
    backgroundSecondary: string
    backgroundDark: string
    filterInput: string
  }
  text: {
    heading: {
      family: string
      weight: number
      colour: string
    },
    body: {
      family: string
      weight: number
      colour: string
    }
  }
  components: {
    header: {
      background: string
      backgroundGradient: string
      shadow: string
      text: string
    },
    footer: {
      background: string
      backgroundImage: string
      text: string
    },
    progressBar: {
      background: string
      fill: string
    },
    button: {
      primary: {
        background: string
        text: string
        shadow: string,
        border: string
      },
      secondary: {
        background: string
        text: string
        shadow: string,
        border: string
      }
    },
    confirmation: {
      incomplete: string
      complete: string
      progress: string
    }
  }
  images: {
    favicon: string
    logo: string
    logoHeader: string,
    icon: string,
    customCarouselImages: boolean
  }
  // defaults
  textSizes: {
    desktop: string
    mobile: string
    app: string
  }
  transitions: {
    duration: {
      fast: string
      default: string
      slow: string
    }
    easing: {
      inOutCubic: string
    }
  }
}

// ------------------------------------------------------------ UTILS

export const getThemeKey = (id: number): ThemeKey => {
  switch (id) {
    case 3:
      return ThemeKey.Chemistworks;
    case 4:
      return ThemeKey.CatAndClaudias;
    case 12: // staging
    case 17: // local, production
      return ThemeKey.FoliumDispensary;
    case 8:
    case 99:
      return ThemeKey.SmithsPharmacy;
    case 98:
      return ThemeKey.Priceline;
    default:
      return ThemeKey.Default;
  }
}

export const getTheme = (theme: ThemeKey | null): ITheme => {
  switch (theme) {
    case ThemeKey.Priceline:
      return {
        id: theme,
        ...pricelineTheme,
        ...defaults
      }
    case ThemeKey.SmithsPharmacy:
      return {
        id: theme,
        ...smithsTheme,
        ...defaults
      }
    case ThemeKey.Chemistworks:
      return {
        id: theme,
        ...chemistworksTheme,
        ...defaults
      }
    case ThemeKey.CatAndClaudias:
      return {
        id: theme,
        ...catAndClaudiasTheme,
        ...defaults
      }
    case ThemeKey.FoliumDispensary:
      return {
        id: theme,
        ...foliumTheme,
        ...defaults
      }
    default:
      // console.warn(`Invalid theme identifier "${theme}", returning default theme.`)
      return {
        id: ThemeKey.Default,
        ...defaultTheme,
        ...defaults
      }
  }
}

// ------------------------------------------------------------ DEFAULTS

const textSizes = {
  desktop: '16px',
  mobile: '16px',
  app: '16px'
}

const transitions = {
  duration: {
    fast: '0.2s',
    default: '0.3s',
    slow: '0.4s'
  },
  easing: {
    inOutCubic: 'cubic-bezier(0.65, 0, 0.35, 1)'
  }
}

const defaults = {
  textSizes: textSizes,
  transitions: transitions
}

// ------------------------------------------------------------ DEFAULT

const defaultData = {
  name: 'Rival Software Pty Ltd',
  dummyLocation: '',
  phoneDisplay: '',
  phone: ''
}

const defaultColours = {
  primary: 'black',
  primaryDark: 'black',
  primaryHighlight: '#D0FFE4',
  secondary: 'grey',
  black: 'black',
  white: 'white',
  grey: '#B3B3B3',
  background: '#F8F8F8',
  backgroundSecondary: '#F3F9FB',
  backgroundDark: '#F3F9FB',
  filterInput: 'brightness(0) saturate(100%)',
  input: 'black'
}

const defaultText = {
  heading: {
    family: '"Geologica"',
    weight: 500,
    colour: defaultColours.primary,
  },
  body: {
    family: '"Geologica"',
    weight: 300,
    colour: defaultColours.black,
  }
}

const defaultComponents = {
  header: {
    background: defaultColours.white,
    backgroundGradient: defaultColours.white,
    shadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
    text: defaultColours.black,
  },
  footer: {
    background: 'none',
    backgroundImage: `none`,
    text: defaultColours.primary
  },
  progressBar: {
    background: defaultColours.background,
    fill: defaultColours.secondary,
  },
  button: {
    primary: {
      background: defaultColours.black,
      text: defaultColours.white,
      shadow: 'none',
      border: 'none'
    },
    secondary: {
      background: defaultColours.white,
      text: defaultColours.black,
      shadow: 'none',
      border: 'none'
    }
  },
  confirmation: {
    incomplete: defaultColours.grey,
    complete: '#8CBC08',
    progress: '#FFAE35'
  }
}

const defaultImages = {
  favicon: ' ',
  logo: '',
  logoHeader: '',
  icon: ' ',
  customCarouselImages: false
}

const defaultTheme = {
  data: defaultData,
  colours: defaultColours,
  text: defaultText,
  components: defaultComponents,
  images: defaultImages,
}

// ------------------------------------------------------------ PRICELINE

const pricelineData = {
  name: 'Priceline',
  dummyLocation: 'Broadway',
  phoneDisplay: '9412 3456',
  phone: '94123456'
}

const pricelineColours = {
  primary: '#D92F8A',
  primaryDark: '#D92F8A',
  primaryHighlight: '#D0FFE4',
  secondary: '#8CBC08',
  black: 'black',
  white: 'white',
  grey: '#B3B3B3',
  background: '#FFFAFC',
  backgroundSecondary: '#F3F9FB',
  backgroundDark: '#F3F9FB',
  filterInput: 'brightness(0) saturate(100%) invert(29%) sepia(79%) saturate(3057%) hue-rotate(309deg) brightness(89%) contrast(90%)',
  input: '#D92F8A'
}

const pricelineText = {
  heading: {
    family: '"Helvetica Neue", Helvetica',
    weight: 500,
    colour: pricelineColours.primary,
  },
  body: {
    family: '"Helvetica Neue", Helvetica',
    weight: 300,
    colour: pricelineColours.black,
  }
}

const pricelineComponents = {
  header: {
    background: pricelineColours.primary,
    backgroundGradient: pricelineColours.primary,
    shadow: '0 4px 4px rgba(63, 3, 35, 0.1)',
    text: pricelineColours.white,
  },
  footer: {
    background: 'none',
    backgroundImage: `url(${footerBackground})`,
    text: pricelineColours.primary
  },
  progressBar: {
    background: '#FFEAF4',
    fill: pricelineColours.secondary,
  },
  button: {
    primary: {
      background: pricelineColours.primary,
      text: pricelineColours.white,
      shadow: '0 3px 4px 0 rgba(92, 5, 52, 0.25)',
      border: 'none'
    },
    secondary: {
      background: pricelineColours.white,
      text: pricelineColours.black,
      shadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
      border: 'none'
    }
  },
  confirmation: {
    incomplete: pricelineColours.grey,
    complete: '#8CBC08',
    progress: '#FFAE35'
  }
}

const pricelineImages = {
  favicon: pricelineFavicon,
  logo: pricelineLogo,
  logoHeader: pricelineLogoHeader,
  icon: ' ',
  customCarouselImages: false
}

const pricelineTheme = {
  data: pricelineData,
  colours: pricelineColours,
  text: pricelineText,
  components: pricelineComponents,
  images: pricelineImages,
}

// ------------------------------------------------------------ SMITH'S PHARMACY

const smithsData = {
  name: 'Smith\'s Pharmacy',
  dummyLocation: 'Broadway',
  phoneDisplay: '9412 3456',
  phone: '94123456'
}

const smithsColours = {
  primary: '#29d97f',
  primaryDark: '#29d97f',
  primaryHighlight: '#D0FFE4',
  secondary: 'dodgerblue',
  black: 'black',
  white: 'white',
  grey: 'lightgrey',
  darkGrey: 'grey',
  background: '#F8F8F8',
  backgroundSecondary: '#F3F9FB',
  backgroundDark: '#E3E9EB',
  filterInput: 'brightness(0) saturate(100%) invert(75%) sepia(57%) saturate(630%) hue-rotate(86deg) brightness(91%) contrast(85%)',
  input: '#29d97f'
}

const smithsText = {
  heading: {
    family: '"Geologica"',
    weight: 500,
    colour: smithsColours.primary,
  },
  body: {
    family: '"Geologica"',
    weight: 300,
    colour: smithsColours.black,
  }
}

const smithsComponents = {
  header: {
    background: smithsColours.white,
    backgroundGradient: `white, #E3E9EB`,
    shadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
    text: smithsColours.black,
  },
  footer: {
    background: 'none',
    backgroundImage: 'none',
    text: smithsColours.darkGrey
  },
  progressBar: {
    background: smithsColours.backgroundDark,
    fill: smithsColours.secondary,
  },
  button: {
    primary: {
      background: smithsColours.black,
      text: smithsColours.white,
      shadow: 'none',
      border: 'none'
    },
    secondary: {
      background: smithsColours.white,
      text: smithsColours.black,
      shadow: 'none',
      border: 'none'
    }
  },
  confirmation: {
    incomplete: smithsColours.grey,
    complete: '#8CBC08',
    progress: '#FFAE35'
  }
}

const smithsImages = {
  favicon: defaultFavicon,
  logo: smithsLogo,
  logoHeader: smithsLogo,
  icon: ' ',
  customCarouselImages: false
}

const smithsTheme = {
  data: smithsData,
  colours: smithsColours,
  text: smithsText,
  components: smithsComponents,
  images: smithsImages,
}

// ------------------------------------------------------------ CHEMISTWORKS

const chemistworksData = {
  name: 'Chemistworks',
  dummyLocation: 'Broadway',
  phoneDisplay: '1800 247 CHEMIST',
  phone: '18002472436478'
}

const chemistworksColours = {
  primary: '#1D2172',
  primaryDark: '#1D2172',
  primaryHighlight: '#D0FFE4',
  secondary: '#3CA9EA',
  black: 'black',
  white: 'white',
  grey: 'lightgrey',
  darkGrey: 'grey',
  background: 'white',
  backgroundSecondary: '#F3F9FB',
  backgroundDark: '#9ad1f5',
  filterInput: 'brightness(0) saturate(100%) invert(14%) sepia(18%) saturate(7060%) hue-rotate(225deg) brightness(99%) contrast(107%)',
  input: '#1D2172'
}

const chemistworksText = {
  heading: {
    family: '"Geologica"',
    weight: 500,
    colour: chemistworksColours.primary,
  },
  body: {
    family: '"Geologica"',
    weight: 300,
    colour: chemistworksColours.black,
  }
}

const chemistworksComponents = {
  header: {
    background: chemistworksColours.white,
    backgroundGradient: `white, ${chemistworksColours.secondary}`,
    shadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
    text: chemistworksColours.black,
  },
  footer: {
    background: 'none',
    backgroundImage: 'none',
    text: chemistworksColours.darkGrey
  },
  progressBar: {
    background: '#F0F0F0',
    fill: chemistworksColours.secondary,
  },
  button: {
    primary: {
      background: chemistworksColours.primary,
      text: chemistworksColours.white,
      shadow: 'none',
      border: 'none'
    },
    secondary: {
      background: chemistworksColours.white,
      text: chemistworksColours.black,
      shadow: 'none',
      border: `1px solid ${chemistworksColours.black}`
    }
  },
  confirmation: {
    incomplete: chemistworksColours.grey,
    complete: '#8CBC08',
    progress: '#FFAE35'
  }
}

const chemistworksImages = {
  favicon: defaultFavicon,
  logo: chemistworksLogo,
  logoHeader: chemistworksLogo,
  icon: '',
  customCarouselImages: false
}

const chemistworksTheme = {
  data: chemistworksData,
  colours: chemistworksColours,
  text: chemistworksText,
  components: chemistworksComponents,
  images: chemistworksImages,
}

// ------------------------------------------------------------ CAT AND CLAUDIAS

const catAndClaudiasData = {
  name: 'Cat & Claudia\'s Community Pharmacy',
  dummyLocation: 'Broadway',
  phoneDisplay: '02 9281 6816',
  phone: '0292816816'
}

const catAndClaudiasColours = {
  primary: '#056E76',
  primaryDark: '#004259',
  primaryHighlight: '#D0FFE4',
  secondary: '#39EA84',
  black: 'black',
  white: 'white',
  grey: 'lightgrey',
  darkGrey: 'grey',
  background: 'white',
  backgroundSecondary: '#F3F9FB',
  backgroundDark: '#91DBE0',
  filterInput: 'filter: invert(71%) sepia(37%) saturate(7353%) hue-rotate(144deg) brightness(96%) contrast(94%);',
  input: '#07B9C5'
}

const catAndClaudiasText = {
  heading: {
    family: '"Geologica"',
    weight: 400,
    colour: catAndClaudiasColours.primaryDark,
  },
  body: {
    family: '"Geologica"',
    weight: 300,
    colour: catAndClaudiasColours.primary,
  }
}

const catAndClaudiasComponents = {
  header: {
    background: `#24DFEC`,
    backgroundGradient: `#24DFEC, #08BAC6`,
    shadow: '0 2px 5px rgba(180, 180, 180, 0.35)',
    text: catAndClaudiasColours.black,
  },
  footer: {
    background: 'none',
    backgroundImage: 'none',
    text: catAndClaudiasColours.darkGrey
  },
  progressBar: {
    background: '#F0F0F0',
    fill: catAndClaudiasColours.secondary,
  },
  button: {
    primary: {
      background: '#FEEF88',
      text: catAndClaudiasColours.primaryDark,
      shadow: 'none',
      border: 'none'
    },
    secondary: {
      background: catAndClaudiasColours.white,
      text: catAndClaudiasColours.primaryDark,
      shadow: 'none',
      border: `1px solid ${catAndClaudiasColours.primaryDark}`
    }
  },
  confirmation: {
    incomplete: catAndClaudiasColours.grey,
    complete: catAndClaudiasColours.secondary,
    progress: '#FFAE35'
  }
}

const catAndClaudiasImages = {
  favicon: defaultFavicon,
  logo: catAndClaudiasLogo,
  logoHeader: catAndClaudiasLogoHeader,
  icon: catAndClaudiasIcon,
  customCarouselImages: true
}

const catAndClaudiasTheme = {
  data: catAndClaudiasData,
  colours: catAndClaudiasColours,
  text: catAndClaudiasText,
  components: catAndClaudiasComponents,
  images: catAndClaudiasImages,
}

// ------------------------------------------------------------ FOLIUM DISPENSARY

const foliumData = {
  name: 'Folium Dispensary',
  dummyLocation: 'North Strathfield',
  phoneDisplay: '02 9139 8219',
  phone: '+61291398219'
}

const foliumColours = {
  primary: '#6E701A',
  primaryDark: 'black',
  primaryHighlight: '#FAF2ED',
  secondary: '#FAF2ED',
  black: 'black',
  white: 'white',
  grey: '#BDB2AB',
  darkGrey: '#BDB2AB',
  background: 'white',
  backgroundSecondary: '#FAF2ED',
  backgroundDark: '#6E701A',
  filterInput: 'brightness(0) saturate(100%) invert(36%) sepia(98%) saturate(334%) hue-rotate(23deg) brightness(93%) contrast(92%);',
  input: '#6E701A'
}

const foliumText = {
  heading: {
    family: '"Geologica"',
    weight: 400,
    colour: foliumColours.primaryDark,
  },
  body: {
    family: '"Geologica"',
    weight: 300,
    colour: foliumColours.primary,
  }
}

const foliumComponents = {
  header: {
    background: foliumColours.secondary,
    backgroundGradient: `${foliumColours.secondary}, ${foliumColours.secondary}`,
    shadow: '0 2px 5px rgba(180, 180, 180, 0.35)',
    text: foliumColours.background,
  },
  footer: {
    background: 'none',
    backgroundImage: 'none',
    text: foliumColours.darkGrey
  },
  progressBar: {
    background: foliumColours.secondary,
    fill: foliumColours.primary,
  },
  button: {
    primary: {
      background: foliumColours.primary,
      text: foliumColours.secondary,
      shadow: 'none',
      border: 'none'
    },
    secondary: {
      background: 'none',
      text: foliumColours.primary,
      shadow: 'none',
      border: `1px solid ${foliumColours.primary}`
    }
  },
  confirmation: {
    incomplete: foliumColours.grey,
    complete: foliumColours.secondary,
    progress: '#FFAE35'
  }
}

const foliumImages = {
  favicon: defaultFavicon,
  logo: foliumLogo,
  logoHeader: foliumLogo,
  icon: ' ',
  customCarouselImages: true
}

const foliumTheme = {
  data: foliumData,
  colours: foliumColours,
  text: foliumText,
  components: foliumComponents,
  images: foliumImages,
}