import { FunctionComponent, useEffect, useState } from "react";
import styled from 'styled-components';
import { useNavigate, Navigate } from "react-router-dom";

import { useApi } from "../context/ApiProvider";
import { ErrorCode, ProductRequest } from "sparrowhub-client-axios";

import { useAppDispatch, useAppSelector } from "../store/hooks";
import { ProgressStepKeys, getPatientName, getProductEntitlementPrice, setConfig, setScript } from "../store/scriptSlice";
import { ThemeKey, getThemeKey } from "../assets/css/variables";

import { Button, ButtonType } from "../components/Button";
import { Checkbox } from "../components/Checkbox";
import { PatientInputType, ScriptBlock } from "../components/ScriptBlock";
import { Loader } from "../components/Loader";
import { BackButton } from "../components/BackButton";

const loaderSteps: Array<string> = [
  'Fetching your prescription...',
  'Fetching your prescription...',
  'Checking a few things...',
  'Done!'
]

type PatientPageProps = {
}

export const PatientPage: FunctionComponent<PatientPageProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const api = useApi();

  // store
  const config = useAppSelector((state) => state.script.config);
  const scripts = useAppSelector((state) => state.script.scripts);

  // state
  const [showPage, setShowPage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [terms, setTerms] = useState(false);
  const [apiResult, setApiResult] = useState<any>(null);

  // computed
  const scriptInputIsInvalid = (): boolean => {
    // check first script
    const formEl = (document.getElementById('form_script-block_patient-details') as HTMLFormElement);

    return (
      scripts[0].entitlement === '' ||
      (scripts[0].entitlement !== 'private' && scripts[0].entitlement_number === '') ||
      scripts[0].customer_firstname === '' ||
      scripts[0].customer_lastname === '' ||
      scripts[0].customer_email === '' ||
      scripts[0].delivery_phone === '' ||
      (formEl && !formEl.checkValidity())
    )
    // check all scripts
    // return scripts.some((script: Prescription) => {
    //   return (
    //     script.entitlement === '' ||
    //     script.customer_firstname === '' ||
    //     script.customer_lastname === '' ||
    //     script.customer_email === '' ||
    //     script.delivery_phone === ''
    //   )
    // })
  }

  const termsUrl = (): string => {
    const themeKey = getThemeKey(config.partner_id!);
    switch (themeKey) {
      case ThemeKey.Chemistworks:
      case ThemeKey.CatAndClaudias:
      case ThemeKey.FoliumDispensary:
        return `https://sparrowhub.com.au/privacy-policy/${themeKey}`
      default:
        return 'https://sparrowhub.com.au/redirect/terms';
    }
  }

  // methods
  const next = (willShowFailover: boolean): void => {
    setShowPage(false);
    setShowLoader(false);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setTimeout(() => {
      navigate('/prescription');

      // log failover state
      if (willShowFailover) {
        setTimeout(() => {
          gtag('event', 'page_view', {
            'active_state': 'failover'
          });
        }, 1000);
      }
    }, 300);
  }

  const handleError = (error: any): void => {
    console.error(error);

    let errorData: any = {};
    if (error === null || error === undefined || error.data === null || error.data === undefined) {
      errorData.error_code = ErrorCode.UnexpectedError;
    } else if (typeof error.data === 'string') {
      try {
        errorData = JSON.parse(error.data);
      } catch (error) {
        errorData.error_code = ErrorCode.UnexpectedError;
      }
    } else {
      errorData.error_code = ErrorCode.BadToken;
    }

    setApiResult(errorData);
    setShowPage(false);
    setShowLoader(false);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    // navigate to error page
    dispatch(setConfig({ error: errorData.error_code }))
    setTimeout(() => {
      navigate('/error');
    }, 300);
  }

  const handleResponse = (apiResponse: any): void => {
    // check price data for all products
    const noPrivatePrice = apiResponse.data.products.every((product: any) => {
      const priceForEntitlement = getProductEntitlementPrice(product, 'private');
      return priceForEntitlement === null || priceForEntitlement === undefined;
    });

    setApiResult(apiResponse);

    // unwrap the medication_knowledge_raw data
    const medication_knowledge = JSON.parse(apiResponse.data.medication_knowledge_raw);
    
    // get patient name
    const { script_firstname, script_lastname } = getPatientName(medication_knowledge);

    // set script in store
    const canAutoSelect = config.auto_select_products || apiResponse.data.products.length === 1;
    dispatch(setScript({
      index: 0,
      products: apiResponse.data.products,
      confirmed: noPrivatePrice ? false : canAutoSelect,
      name: canAutoSelect ? apiResponse.data.products[0].name : undefined,
      dosage: medication_knowledge.entry[0].resource.dosageInstruction,
      script_firstname,
      script_lastname
    }));

    // navigate to help page if EVERY product does not have a price for the selected entitlement
    if (noPrivatePrice) {
      // track error
      gtag('event', 'get_products', {
        'success': false,
        'error_code': 'NoPriceForEntitlement',
        'results': apiResponse.data.products.length,
        'response_type': 'error'
      });
      
      setShowPage(false);
      setShowLoader(false);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      setTimeout(() => {
        dispatch(setConfig({ error: ErrorCode.NoResults }));
        navigate('/error');
      }, 300);
    } else {
      // proceed
      next(!canAutoSelect);
    }
  }

  const beginLoader = async (): Promise<void> => {
    // manually submit GA event
    gtag('event', 'form_submit', {
      'form_id': 'form_script-block_patient-details',
      'form_name': 'Enter patient details',
      'form_submit_text': 'Confirm Prescription'
    });

    if (scripts[0].entitlement === 'safety_net') {
      // navigate to safety net page if necessary
      navigate('/safety-net')
    } else {
      // else proceed as normal
      // show loader
      setShowPage(false);
      setShowLoader(true);

      // generate reCAPTCHA token
      await grecaptcha.enterprise.ready(async () => {
        const token = await grecaptcha.enterprise.execute(config.recaptcha_key, { action: 'get_products' });
        handleGetProducts(token);
      });
    }
  }

  const handleGetProducts = (token: string): void => {
    const requestBody: ProductRequest = {
      partner: { id: config.partner_id! },
      prescription_token_number: scripts[0].token,
      security_token: token,
      send_admin_notification: true
    }

    api.getEscriptProducts(requestBody).then(async (response) => {
      trackGetProducts(response);
      handleResponse(response);
    })
      .catch(error => {
        if (error.response === undefined) {
          handleError(null)
        } else {
          // set script in store for error page if possible
          let apiResponse;
          try {
            apiResponse = JSON.parse(error.response.data);
          } catch (e) {
            apiResponse = error.response.data;
          }

          let medication_knowledge;
          try {
            medication_knowledge = JSON.parse(apiResponse.original);
          } catch (e) {
            medication_knowledge = apiResponse.original;
          }

          if (medication_knowledge && medication_knowledge.resourceType === 'Bundle' && medication_knowledge.entry[0].resource.resourceType !== 'OperationOutcome') {
            try {
              dispatch(setScript({
                index: 0,
                products: [],
                confirmed: false,
                name: medication_knowledge.entry[0].resource.medicationReference.display,
                dosage: medication_knowledge.entry[0].resource.dosageInstruction,
                script_firstname: '',
                script_lastname: ''
              }));
            } catch (error) {
              console.error('Unable to set script in store', error);
            } 
          }

          trackGetProducts(error.response);
          handleError(error.response);
        }
      })
  }

  const trackGetProducts = (apiResponse: any): void => {
    if ((apiResponse === undefined) || (apiResponse.data === undefined)) {
      // if no data, unexpected error
      dispatch(setConfig({ error: ErrorCode.UnexpectedError }));
      navigate('/error');
    } else {
      // else proceed as normal
      let data;
      if (typeof apiResponse.data === 'string') {
        data = JSON.parse(apiResponse.data);
      } else {
        data = apiResponse.data;
      }

      const responseType =
        data.products
          ? (config.auto_select_products || data.products.length === 1) ? 'match' :
            data.products.length > 1 ? 'failover' :
              'error'
          : 'error';

      // track product request result
      gtag('event', 'get_products', {
        'success': data.success,
        'error_code': data.error_code || '',
        'results': data.products ? data.products.length : 0,
        'response_type': responseType
      });

      // track add to cart if match
      if (responseType === 'match') {
        const product = data.products[0];
        const productPrice = getProductEntitlementPrice(data.products[0], scripts[0].entitlement);

        gtag('event', 'add_to_cart', {
          'currency': 'AUD',
          'value': productPrice,
          'items': [
            {
              'item_id': product.barcode,
              'item_name': product.name,
              'item_brand': product.brand_name,
              'price': productPrice,
              'quantity': 1,
            }
          ]
        });
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      dispatch(setConfig({ progress_step_key: ProgressStepKeys.Patient }));
      setShowPage(true);
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  return (
    <>
      {!config.has_landed ?
        <Navigate to="/" />
        :
        <>
          <StyledPatientPage className={`pageTransition ${!showPage && 'hidden'}`}>
            <ScriptBlock script={scripts[0]} scriptIndex={0} key={`ScriptBlock-0`} patientInputType={PatientInputType.Form} />
            {/* {scripts.map((script: Prescription, i: number) => {
              if (script.confirmed) {
                return (
                  <ScriptBlock script={script} scriptIndex={i} key={`ScriptBlock-${i}`} patientInputType={i === 0 ? PatientInputType.Form : PatientInputType.Toggle} />
                )
              } else {
                return null
              }
            })} */}

            <div style={{ margin: '25px 0 35px 0' }}>
              <Checkbox id="terms" selected={terms} onChange={() => setTerms(terms => !terms)} required>
                <>I agree to the SparrowScripts <a className="link" href={termsUrl()} target="_blank" rel="noreferrer">Terms of Use and Privacy Policy</a></>
              </Checkbox>
            </div>

            <Button text="Confirm Prescription" type={ButtonType.Primary} onClick={beginLoader} disabled={!terms || scriptInputIsInvalid()} />
          </StyledPatientPage>

          <Loader show={showLoader} steps={loaderSteps} canComplete={apiResult !== null} />
        </>
      }
    </>
  );
}

const StyledPatientPage = styled.div`

`;
